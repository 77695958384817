.creamb{
    text-transform: uppercase;
    text-align: start;
    
}
.creamb p{
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 40px;
    font-size: 20px;
    padding:3px ;
   color:black
  

}
.creambtext{
    color: rgb(0, 0, 0);
}
.text-light{
    color: white !important;
}
