
.text-light{
  color:white !important
}
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
a{
  color: black;
}
a:hover{
  color:black
}
body{
  font-family: 'Cairo', sans-serif !important;
  height: 100vh !important;
}
input:focus::placeholder {
  margin: 10px;
}
input:focus::placeholder {
  font-size: 12px; /* Modifier la taille de la police du placeholder */
  transform: scale(0.8); /* Modifier la taille du placeholder en le réduisant */
  transform-origin: right top;
  /* Modifier l'origine de la transformation pour que le placeholder rétrécisse depuis le coin supérieur gauche */
}
:root{
  --color-7011f5 : #db9807;
  --color-6f42c1 : #3c0999;
  --color-b911c9: #b911c9;
  --color-131921:#131921;
  --color-3b4149: #3b4149;
  --color-febd69:#febd69;
  --color-232f3e:#232f3e;
  --color-bf4800:#c5049bd8;
  --color-f5f5f7:#f5f5f7;
}

.button{
 
  border: none;
  color: rgb(255, 255, 255);
 /* Pour les navigateurs basés sur WebKit */
  color: transparent; /* Pour les navigateurs basés sur Blink */
  background-image: linear-gradient(to right, rgb(238, 20, 56),rgb(52, 2, 133));
  font-size: 15px;
 
}
a{
  text-decoration: none;
  display: inline-block;
}
.gap-30{
  gap:30px
}
.gap-20{
  gap:20px
}
::placeholder {
  font-family: 'Cairo', sans-serif;
  letter-spacing: 2px;
  color: var();
}

.input-form{
  border: 1px solid var(--color-6f42c1) !important;
}

