.ant-layout-content{
    height: 130vh !important;
}
.btnn{
   
        margin: 0 10px;
        padding: 10px 20px;
        border-radius: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 19px;
        font-weight: 600;
      
}
.btnn:hover{
    background-color: black;
}