.confirm_wrapper{
    position: fixed;
    top: 0;
    left:0;
    z-index: 4;
    background: #0008;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.form{
    width: 350px;
    height: 150px  !important;
    background-color: white;
    border:1px solid #ddd ;
    margin: 100px auto;

    border-radius: 10px;
}