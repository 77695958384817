input:focus::placeholder {
    margin: 10px;
  }
  input:focus::placeholder {
    font-size: 12px; /* Modifier la taille de la police du placeholder */
    transform: scale(0.8); /* Modifier la taille du placeholder en le réduisant */
    transform-origin: right top;
    /* Modifier l'origine de la transformation pour que le placeholder rétrécisse depuis le coin supérieur gauche */
  }
  .title-text{
    font-size: 16px;
    font-weight: 300;
  }
  ::placeholder {
    font-family: 'Cairo', sans-serif;
    letter-spacing: 2px;
    color: var();
  }
  
  .input-form{
    border: 1px solid var(--color-6f42c1) !important;
  }