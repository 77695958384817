.gap-10{
    gap:10px
    }
    .gap-20{gap:20px}
    .gap-30{gap:30px}
    .form-login{
       
    
        /* box-shadow:0 0 10px #416b51 ;
        padding: 10px 20px;
        border-radius: 10px;
         margin: 180px auto;  */ 
      
    }




body{
    height: max-content;
    overflow-y: scroll;
}
/* 

.login-wrapper .circle{
 width: 120px;
 height: 120px;
 border: 0.5px solid #ddd;
 display: flex;
 justify-content: center;
 align-items: center;
margin:10px;
 border-radius: 50%;
 position: relative;
 animation: propagate 50s linear infinite  ; 
}
.login-wrapper .circle2{
    width: 120px;
    height: 120px;
    border: 0.5px solid #ddd;
   margin:10px;
    border-radius: 50%;
    position: relative;
    animation: propagate 50s linear infinite  ;   
}
.login-wrapper .circle3{
    width: 120px;
    height: 120px;
    border: 0.5px solid #ddd;
   
    border-radius: 50%;
    position: relative;
    animation: propagate 50s linear infinite  ;   
}

@keyframes propagate {
    0%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(3);
        opacity: 0;
    }
} */
   .form-login > h5{
    font-size: 40px;
    font-weight: 900;
 
    background-clip: text;
  -webkit-text-fill-color: transparent; /* Pour les navigateurs basés sur WebKit */
  color: transparent; /* Pour les navigateurs basés sur Blink */
  background-image: linear-gradient( rgb(202, 7, 39),rgb(52, 2, 133));
   }
   body{
    font-family: 'Cairo', sans-serif;
  }
  input{
    padding: 4px;
  }

  @media screen and (max-width:600px) {
    .login-wrapper .form-login-registre .swipper{
        display: none;
    }
    .login-wrapper .form-login-registre .wwwww{
        width: 100% !important;
        padding: 20px 10px;
        margin:0 auto  !important;
    }
   
  }