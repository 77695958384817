.pagination{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top:1rem ;
   
}
.pagination button{
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent;
    color: #0c0c0c;

}
.pagination button:hover{
    font-weight: 900;
    border-color: #101010;
    color:#eeeeee;
    background: #1677ff;
}
.pagination-mode-dark button{
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #1677ff;
    color: white;
    margin-top:20px ;
}
.pagination-mode-dark button:hover{
    color: #0c0c0c;
}